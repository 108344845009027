<template>
  <div class="center pagination">
    <vs-pagination v-model="page" :length="dataLength" />
  </div>
</template>

<script>
export default {
  props: ['dataLength'],
  emits: ['p-change'],
  data: () => ({
    page: 1,
  }),
  watch: {
    page(val) {
      this.$emit('p-change', val);
    },
  },
};
</script>

<style lang="sass">
.pagination
	margin-top: 30px
</style>
