<template>
  <div class="favorite" v-if="loaded && favorits.length">
    <div :class="[open ? 'active' : '', 'favorite__list']">
      <div class="favorite__item" v-for="f in favorits" :key="f.id">
        <a :href="f.link" target="_blank">{{ f.link }}</a>
        <vs-button
          circle
          icon
          flat
          primary
          class="favorite__item--delete"
          @click="deleteFavorite(f.id)"
        >
          <i class="bx bx-minus"></i>
        </vs-button>
      </div>
    </div>
    <vs-button circle icon flat class="xl favorite__btn" @click="openFavorite">
      <i class="bx bxs-star"></i>
    </vs-button>
  </div>
</template>

<script>
export default {
  data: () => ({
    loaded: false,
    open: true,
  }),
  methods: {
    openFavorite() {
      this.open = !this.open;
    },
    getFav(id) {
      return this.$store.getters.site(id);
    },
    deleteFavorite(id) {
      this.$store.dispatch('removeFavorite', id);
      let favorits = JSON.parse(localStorage.getItem('favorits'));
      favorits = favorits.filter((f) => f !== id);
      localStorage.setItem('favorits', JSON.stringify(favorits));
    },
  },
  computed: {
    favorits() {
      return this.$store.getters.getData
        ? this.$store.getters.getData.filter((item) => item.favorit)
        : [];
    },
  },
  mounted() {
    setTimeout(() => (this.loaded = true), 1000);
  },
};
</script>

<style lang="sass">
.favorite__btn.xl
	transform: scale(2)
	position: fixed
	right: 50px
	bottom: 50px
	z-index: 700
.favorite__list
	position: fixed
	right: 0
	top: 0
	height: 100vh
	padding: 44px 0
	width: 300px
	background: rgba(var(--vs-background), 1)
	overflow-y: scroll
	transform: translateX(100%)
	transition: .3s ease
	z-index: 600
	&.active
		transform: translateX(0%)
.favorite__item
	word-wrap: break-word
	border-bottom: 1px solid var(--vs-theme-bg)
	padding: 10px 20px
	display: block
	position: relative
	height: 80px
	a
		color: rgba(var(--vs-text), 1)
		text-decoration: none
		font-size: 16px
	&:hover
		background-color: var(--vs-theme-bg)
		.favorite__item--delete
			visibility: visible
.favorite__item--delete
	top: 0
	left: 100%
	transform: translateY(-50%) translateX(-100%)
	z-index: 2
	visibility: hidden
@media only screen and (max-width: 1200px)
	.favorite__item--delete
		visibility: visible
@media only screen and (max-width: 768px)
	.favorite__list
		bottom: 0
		left: 0
		top: auto
		right: auto
		width: 100%
		height: 200px
		padding: 10px 0
		transform: translateY(100%)
		&.active
			transform: translateY(0%)
	.favorite__item
		display: flex
		align-items: center
		justify-content: space-between
		padding: 5px 10px
		height: auto
		a
			word-wrap: break-word
			display: block
			width: calc(100% - 45px)
	.favorite__item--delete
		transform: none
		left: auto
		min-width: 35px
</style>
