<template>
  <div class="adminInfo">
    <h1>Информация</h1>
    <div class="adminInfo__list">
      <div class="adminInfo__item">
        <AdminDoughnut :data="data" />
      </div>
      <div class="adminInfo__item">
        <div class="adminInfo__label">Количество сайтов</div>
        <div class="adminInfo__val">
          {{ data.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminDoughnut from '@/components/AdminDoughnut.vue';
export default {
  components: { AdminDoughnut },
  computed: {
    data() {
      return this.$store.getters.getData;
    },
  },
};
</script>

<style lang="sass">
.adminInfo
	&__list
		display: grid
		gap: 20px
		grid-template-columns: repeat(4, 1fr)
	&__item
		background: rgba(var(--vs-background), 1)
		border-radius: 15px
		padding: 20px
	&__label
		margin-bottom: 10px
		font-size: 20px
	&__val
		font-size: 24px
		font-weight: 700
</style>
