<template>
  <div class="container login">
    <h1>Авторизация</h1>
    <login-form />
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm.vue';
export default {
  name: 'LoginView',
  components: { LoginForm },
};
</script>
<style lang="sass">
.login
	height: calc(100vh - 64px)
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
</style>
