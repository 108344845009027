<template>
  <div class="catalogSite">
    <h1>{{ site.link }}</h1>
    <form
      class="catalogEdit"
      action="#"
      @submit.prevent="saveSite"
      method="put"
    >
      <div class="catalogEdit_input">
        <vs-select
          filter
          placeholder="Тип"
          v-model="type"
          @change="setKey('type')"
        >
          <vs-option label="Автомобили" value="auto"> Автомобили </vs-option>
          <vs-option label="Недвижимость" value="realty">
            Недвижимость
          </vs-option>
          <vs-option label="сервис" value="service"> Сервис </vs-option>
          <vs-option label="Выкуп" value="buyout"> Выкуп </vs-option>
          <vs-option label="Прочее" value="other"> Прочее </vs-option>
        </vs-select>
      </div>
      <div
        class="catalogEdit_input"
        v-if="
          (site.type === 'auto' && marks.length) ||
          (site.type === 'service' && marks.length)
        "
      >
        <vs-select
          filter
          placeholder="Марка"
          v-model="mark"
          @change="setKey('mark')"
        >
          <vs-option
            v-for="mark in marks"
            :key="mark"
            :label="mark"
            :value="mark"
          >
            {{ mark }}
          </vs-option>
        </vs-select>
      </div>
      <div
        class="catalogEdit_input"
        v-if="site.type === 'auto' || site.type === 'service'"
      >
        <vs-input v-model="site.diler" placeholder="Дилер" />
      </div>
      <div class="catalogEdit_input">
        <vs-input v-model="site.link" placeholder="Ссылка" />
      </div>
      <div class="catalogEdit_input">
        <vs-select placeholder="Год" v-model="year" @change="setKey('year')">
          <vs-option label="2017" value="2017"> 2017 </vs-option>
          <vs-option label="2018" value="2018"> 2018 </vs-option>
          <vs-option label="2019" value="2019"> 2019 </vs-option>
          <vs-option label="2020" value="2020"> 2020 </vs-option>
          <vs-option label="2021" value="2021"> 2021 </vs-option>
          <vs-option label="2022" value="2022"> 2022 </vs-option>
          <vs-option label="2023" value="2023"> 2023 </vs-option>
          <vs-option label="2024" value="2024"> 2024 </vs-option>
        </vs-select>
      </div>
      <div class="catalogEdit_input">
        <div class="catalogEdit__upload">
          <img
            :src="imageUrl()"
            v-if="preview || site.screenshot"
            class="catalogEdit__upload--preview"
          />
          <file-upload
            :drop="true"
            :drop-directory="true"
            :multiple="false"
            ref="upload"
            v-model="file"
            @input-filter="inputFilter"
          >
            <vs-button flat>
              <span v-if="!file[0]">
                <i class="bx bx-image-add"></i>
                <span v-if="site.screenshot">Изменить скриншот</span>
                <span v-else>Добавить скриншот</span>
              </span>

              <i class="bx bx-check" v-else></i>
            </vs-button>
            <p
              class="catalogEdit__upload--title"
              v-show="$refs.upload && $refs.upload.dropActive"
            >
              Перетащите файл для загрузки
            </p>
          </file-upload>
        </div>
      </div>
      <vs-button
        :icon="success"
        :upload="sending"
        type="submit"
        :color="success ? 'success' : 'primary'"
      >
        <span v-if="!success">
          <i class="bx bx-save"></i>
          Сохранить
        </span>

        <i class="bx bx-check" v-else></i>
      </vs-button>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    url: 'https://api.tandemdev.ru:10000/',
    file: [],
    type: '',
    mark: '',
    diler: '',
    year: '',
    site: {},
    sending: false,
    success: false,
  }),
  methods: {
    imageUrl() {
      return this.file[0] && this.file[0].blob
        ? this.file[0].blob
        : this.url + this.site.screenshot;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent();
        }
      }
      // Create a blob field
      newFile.blob = '';

      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    async saveSite() {
      if (this.sending) return false;
      this.sending = true;
      if (this.file.length) {
        const formData = new FormData();
        formData.append('file', this.file[0].file);
        formData.append('id', this.site.id);
        await this.$http({
          method: 'post',
          url: `https://api.tandemdev.ru:10000/sites/upload`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            this.site.screenshot = response.data.data.path;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.sending = false;
            console.error('Ошибка загрузки файла', error);
            return false;
          });
      }

      await this.$http({
        method: 'put',
        url: `https://api.tandemdev.ru:10000/sites/${this.site.id}`,
        data: this.site,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          this.success = !this.success;
          setTimeout(() => {
            this.success = !this.success;
            this.sending = false;
            this.$router.push('/admin/catalog/');
          }, 1000);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.sending = false;
          console.error('There was an error!', error);
        });
    },
    setKey(key) {
      this.site[key] = this[key];
    },
  },
  created() {
    this.$http
      .get(`https://api.tandemdev.ru:10000/sites/${this.$route.params.id}`)
      .then((response) => {
        this.site = response.data;
        this.type = this.site.type;
        this.mark = this.site.mark;
        this.diler = this.site.diler;
        this.year = this.site.year;
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error('There was an error!', error);
      });
  },
  computed: {
    preview() {
      return this.file[0] && this.file[0].blob ? true : false;
    },
    marks() {
      return this.$store.getters.getMarks;
    },
  },
};
</script>

<style lang="sass">
.catalogEdit
	background: rgba(var(--vs-background), 1)
	display: inline-block
	padding: 20px
	border-radius: 8px
	&__upload
		position: relative
		width: 100%
		height: 100px
		box-shadow: 0px -5px 25px -4px rgba(0, 0, 0, var(--vs-shadow-opacity)) !important
		border-radius: 12px
		display: flex
		justify-content: center
		align-items: center
		overflow: hidden
		.file-uploads
			position: absolute
			left: 0
			top: 0
			bottom: 0
			right: 0
			display: flex
			align-items: center
			justify-content: center
			background: rgba(var(--vs-gray-2), 1)
			flex-wrap: wrap
			object-fit: cover
	&__upload--preview
		display: block
		width: 100%
		height: auto
		position: absolute
		left: 0
		top: 0
		z-index: 1
	&__upload--title
		font-weight: bold
		text-align: center
		z-index: 2
	&_input
		margin-bottom: 15px
</style>
