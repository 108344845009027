<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    chart-id="AdminDoughnut"
    :width="300"
    :height="300"
  />
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  props: ['data'],
  components: {
    Doughnut,
  },
  data: () => ({
    chartData: null,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
    },
  }),
  created() {
    const labels = [...new Set(this.data.map((item) => item.type))];
    const arr = [];
    labels.map((type) =>
      arr.push({
        label: type,
        count: this.data.filter((item) => item.type === type).length,
      })
    );
    const labelsNames = this.$store.getters.types;
    labels.map((l, index) =>
      labelsNames.map((ln) => {
        if (l === ln.id) labels[index] = ln.name;
      })
    );
    this.chartData = {
      labels: labels,
      datasets: [
        {
          backgroundColor: [
            '#41B883',
            '#E46651',
            '#00D8FF',
            '#DD1B16',
            '#cfa999',
          ],
          data: arr.map((item) => item.count),
        },
      ],
    };
  },
};
</script>

<style lang="sass"></style>
