<template>
  <div class="adminSideBar">
    <vs-sidebar absolute v-model="active" open>
      <vs-sidebar-item id="home" to="/admin">
        <template #icon>
          <i class="bx bx-home"></i>
        </template>
        Информация
      </vs-sidebar-item>
      <vs-sidebar-item id="catalog" to="/admin/catalog">
        <template #icon>
          <i class="bx bx-home"></i>
        </template>
        Каталог сайтов
      </vs-sidebar-item>
    </vs-sidebar>
  </div>
</template>

<script>
export default {
  data: () => ({
    active: 'home',
  }),
};
</script>

<style lang="sass">
.adminSideBar
	opacity: 1
	transition: 1s ease
	.vs-sidebar-content
		top: 44px
		&.absolute
			height: calc(100% - 44px)
</style>
