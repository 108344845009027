import Vue from 'vue';
import Vuesax from 'vuesax';
import App from './App.vue';
import router from './router';
import store from './store';
import Axios from 'axios';
import 'boxicons/css/boxicons.min.css';
import 'vuesax/dist/vuesax.css';
const VueUploadComponent = require('vue-upload-component');
Vue.component('file-upload', VueUploadComponent);
Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.use(Vuesax, {
  colors: {
    primary: '#f7ac19',
    success: 'rgb(23, 201, 100)',
    danger: '#f53a27',
    warning: 'rgb(255, 130, 0)',
    dark: '#1e2123',
  },
});
new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount('#app');
