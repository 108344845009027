<template>
  <div class="admin">
    <AdminInfo />
  </div>
</template>

<script>
import AdminInfo from '@/components/AdminInfo.vue';
export default {
  components: { AdminInfo },
};
</script>

<style lang="sass"></style>
