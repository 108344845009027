<template>
  <header class="header">
    <vs-navbar center-collapsed notLine v-model="nav">
      <template #left>
        <div class="header__logo" @click="openPage('/')">TANDEM DEV</div>
      </template>
      <vs-navbar-item id="home" to="/" v-if="isLoggedIn">
        Главная
      </vs-navbar-item>
      <vs-navbar-item id="admin" to="/admin" v-if="isLoggedIn">
        Админ панель
      </vs-navbar-item>
      <vs-navbar-item
        href="https://tandemdigital.ru/access/"
        target="_blank"
        v-if="isLoggedIn"
      >
        FTP Доступы
      </vs-navbar-item>
      <template #right>
        <vs-switch dark v-model="darkTheme" @change="changeTheme">
          <template #off>
            <i class="bx bxs-moon"></i>
          </template>
          <template #on>
            <i class="bx bxs-sun"></i>
          </template>
        </vs-switch>

        <vs-button @click="logout" v-if="isLoggedIn">
          <i class="bx bx-log-out-circle"></i> <span>Выйти</span></vs-button
        >
        <vs-button to="/login" v-else>
          <i class="bx bx-log-in-circle"></i>
          <span>Авторизация</span></vs-button
        >
      </template>
    </vs-navbar>
  </header>
</template>

<script>
export default {
  name: 'NavBar',
  data: () => ({ nav: 'home', darkTheme: false }),
  methods: {
    openPage(page) {
      this.$router.push(page);
    },
    changeTheme() {
      if (this.darkTheme) {
        document.body.classList.add('darken');
        document.body.setAttribute('vs-theme', 'dark');
      } else {
        document.body.classList.remove('darken');
        document.body.removeAttribute('vs-theme');
      }
      this.$store.dispatch('getDarkTheme');
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      });
    },
  },
  created() {
    this.darkTheme = this.$store.getters.darkTheme;
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style lang="sass">
.header__logo
	font-weight: 800
	cursor: pointer
	font-size: 24px
</style>
