<template>
  <div class="catalogCreate">
    <h1>Добавить сайт</h1>
    <div class="catalogCreate__wrapper">
      <div class="catalogCreate_input">
        <vs-select filter placeholder="Тип" v-model="siteModel.type">
          <vs-option label="Автомобили" value="auto"> Автомобили </vs-option>
          <vs-option label="Недвижимость" value="realty">
            Недвижимость
          </vs-option>
          <vs-option label="сервис" value="service"> Сервис </vs-option>
          <vs-option label="Выкуп" value="buyout"> Выкуп </vs-option>
          <vs-option label="Прочее" value="other"> Прочее </vs-option>
        </vs-select>
      </div>
      <div
        class="catalogCreate_input"
        v-if="
          (siteModel.type === 'auto' && marks.length) ||
          (siteModel.type === 'service' && marks.length)
        "
      >
        <vs-select filter placeholder="Марка" v-model="siteModel.mark">
          <vs-option
            v-for="mark in marks"
            :key="mark"
            :label="mark"
            :value="mark"
          >
            {{ mark }}
          </vs-option>
        </vs-select>
      </div>
      <div
        class="catalogCreate_input"
        v-if="siteModel.type === 'auto' || siteModel.type === 'service'"
      >
        <vs-input v-model="siteModel.diler" placeholder="Дилер" />
      </div>
      <div class="catalogCreate_input">
        <vs-input v-model="siteModel.link" placeholder="Ссылка" />
      </div>
      <div class="catalogCreate_input">
        <vs-select placeholder="Год" v-model="siteModel.year">
          <vs-option label="2017" value="2017"> 2017 </vs-option>
          <vs-option label="2018" value="2018"> 2018 </vs-option>
          <vs-option label="2019" value="2019"> 2019 </vs-option>
          <vs-option label="2020" value="2020"> 2020 </vs-option>
          <vs-option label="2021" value="2021"> 2021 </vs-option>
          <vs-option label="2022" value="2022"> 2022 </vs-option>
          <vs-option label="2023" value="2023"> 2023 </vs-option>
          <vs-option label="2024" value="2024"> 2024 </vs-option>
        </vs-select>
      </div>
      <div class="catalogCreate_input">
        <div class="catalogCreate__upload">
          <img
            :src="imageUrl()"
            v-if="preview || siteModel.screenshot"
            class="catalogCreate__upload--preview"
          />
          <file-upload
            :drop="true"
            :drop-directory="true"
            :multiple="false"
            ref="upload"
            v-model="file"
            @input-filter="inputFilter"
          >
            <vs-button flat>
              <span v-if="!file[0]">
                <i class="bx bx-image-add"></i>
                <span v-if="siteModel.screenshot">Изменить скриншот</span>
                <span v-else>Добавить скриншот</span>
              </span>

              <i class="bx bx-check" v-else></i>
            </vs-button>
            <p
              class="catalogCreate__upload--title"
              v-show="$refs.upload && $refs.upload.dropActive"
            >
              Перетащите файл для загрузки
            </p>
          </file-upload>
        </div>
      </div>
    </div>

    <div class="catalogCreate_send">
      <vs-button
        :icon="success"
        :upload="sending"
        @click="save"
        :color="success ? 'success' : 'primary'"
      >
        <span v-if="!success">
          <i class="bx bx-plus"></i>
          Добавить
        </span>

        <i class="bx bx-check" v-else></i>
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    siteModel: {
      type: '',
      mark: '',
      diler: '',
      link: '',
      on: true,
      year: '',
      id: +new Date(),
    },
    url: 'https://api.tandemdev.ru:10000/',
    file: [],
    sending: false,
    success: false,
  }),
  methods: {
    imageUrl() {
      return this.file[0] && this.file[0].blob
        ? this.file[0].blob
        : this.url + this.siteModel.screenshot;
    },
    clearObj() {
      Object.keys(this.siteModel).forEach(
        (k) => this.siteModel[k] === '' && delete this.siteModel[k]
      );
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent();
        }
      }
      // Create a blob field
      newFile.blob = '';

      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    async saveImage() {
      const formData = new FormData();
      formData.append('file', this.file[0].file);
      formData.append('id', this.siteModel.id);
      await this.$http({
        method: 'post',
        url: `https://api.tandemdev.ru:10000/sites/upload`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          this.siteModel.screenshot = response.data.data.path;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.sending = false;
          console.error('Ошибка загрузки файла', error);
          return false;
        });
    },
    async saveSite() {
      await this.$http
        .post(`https://api.tandemdev.ru:10000/sites/`, this.siteModel)
        .then((response) => {
          this.$store.dispatch('fetchData');
          this.success = !this.success;
          setTimeout(() => {
            this.success = !this.success;
            this.sending = false;
            this.$router.push('/admin/catalog/');
          }, 1000);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.sending = false;
          console.error('There was an error!', error);
        });
    },
    save() {
      if (this.sending) return false;
      this.sending = true;
      this.clearObj();
      if (this.file.length) {
        this.saveImage().then(() => this.saveSite());
      } else {
        this.saveSite();
      }
    },
  },
  computed: {
    preview() {
      return this.file[0] && this.file[0].blob ? true : false;
    },
    marks() {
      return this.$store.getters.getMarks;
    },
  },
};
</script>

<style lang="sass">
.catalogCreate__wrapper
	background: rgba(var(--vs-background), 1)
	display: inline-block
	border-radius: 8px
	margin-bottom: 15px
	padding: 20px
.catalogCreate_input
	margin-bottom: 15px
.catalogCreate
	&__upload
		position: relative
		width: 100%
		height: 100px
		box-shadow: 0px -5px 25px -4px rgba(0, 0, 0, var(--vs-shadow-opacity)) !important
		border-radius: 12px
		display: flex
		justify-content: center
		align-items: center
		overflow: hidden
		.file-uploads
			position: absolute
			left: 0
			top: 0
			bottom: 0
			right: 0
			display: flex
			align-items: center
			justify-content: center
			background: rgba(var(--vs-gray-2), 1)
			flex-wrap: wrap
			object-fit: cover
	&__upload--preview
		display: block
		width: 100%
		height: auto
		position: absolute
		left: 0
		top: 0
		z-index: 1
	&__upload--title
		font-weight: bold
		text-align: center
		z-index: 2
</style>
