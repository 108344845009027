<template>
  <div class="home">
    <h1>Примеры посадок</h1>
    <cards-list />
    <favorite />
  </div>
</template>

<script>
import Favorite from '@/components/Favorite.vue';
import CardsList from '@/components/CardsList.vue';
export default {
  name: 'HomeView',
  components: { CardsList, Favorite },
};
</script>
