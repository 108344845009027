<template>
  <div class="center adminCatalog">
    <vs-table>
      <template #thead>
        <vs-tr>
          <vs-th> Ссылка </vs-th>
          <vs-th sort @click="data = $vs.sortData($event, data, 'type')">
            Тип
          </vs-th>
          <vs-th sort @click="data = $vs.sortData($event, data, 'year')">
            Год
          </vs-th>
        </vs-tr>
      </template>
      <template #tbody>
        <vs-tr
          :key="i"
          v-for="(tr, i) in $vs.getPage(data, page, max)"
          :class="[tr.on ? '' : 'adminCatalog__off']"
        >
          <vs-td>
            <a :href="tr.link" class="adminCatalog__link" target="_blank">{{
              tr.link
            }}</a>
          </vs-td>
          <vs-td>
            {{ typeName(tr.type) }}
          </vs-td>
          <vs-td>
            {{ tr.year }}
          </vs-td>

          <template #expand>
            <div class="con-content">
              <div>
                <p v-if="tr.mark" class="adminCatalog__info">
                  Марка: <b class="uppercase">{{ tr.mark }}</b>
                </p>
                <p v-if="tr.diler">
                  Дилер: <b class="uppercase">{{ tr.diler }}</b>
                </p>
              </div>
              <div>
                <vs-switch
                  flat
                  v-model="data[i].on"
                  @click="showChange(tr)"
                  :disabled="loading"
                >
                  <template #off>
                    <i class="bx bx-hide"></i>
                  </template>
                  <template #on>
                    <i class="bx bx-show"></i>
                  </template>
                </vs-switch>

                <vs-button @click="goToPage(tr)"> Редактировать </vs-button>
                <vs-button border danger @click="deleteSite(tr.id)"
                  >Удалить
                </vs-button>
              </div>
            </div>
          </template>
        </vs-tr>
      </template>
      <template #footer>
        <vs-pagination v-model="page" :length="$vs.getLength(data, max)" />
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    page: 1,
    max: 10,
    loading: false,
    data: [],
  }),
  watch: {
    page() {
      this.closeExpand();
    },
  },
  methods: {
    typeName(id) {
      return this.$store.getters.typeName(id);
    },
    goToPage(site) {
      this.$router.push({ path: `/admin/catalog/${site.id}` });
    },
    closeExpand() {
      const isExpandTr = document.querySelectorAll(
        '.vs-table__tr.isExpand.expand'
      );
      for (const tr of isExpandTr) {
        tr.click();
      }
    },
    deleteSite(id) {
      this.$http
        .delete(`https://api.tandemdev.ru:10000/sites/${id}`)
        .then((response) => {
          this.closeExpand();
          this.refreshData();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error('There was an error!', error);
        });
    },
    refreshData() {
      this.$store.dispatch('fetchData').then(() => {
        this.data = this.$store.getters.getData;
      });
    },
    showChange(site) {
      if (this.loading) return false;
      this.loading = true;
      site.on = !site.on;
      this.$http
        .put(`https://api.tandemdev.ru:10000/sites/${site.id}`, site)
        .then((response) => {
          this.loading = false;
          this.refreshData();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.loading = false;
          console.error('There was an error!', error);
        });
    },
  },
  created() {
    this.refreshData();
  },
};
</script>

<style lang="sass">
.adminCatalog
	.vs-table-content
		background: rgba(var(--vs-background), 1)
	&__link
		color: rgba(var(--vs-bg), 1)
		text-decoration: none
	&__off
		opacity: 0.5
	&__info
		margin-right: 20px
</style>
