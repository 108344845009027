<template>
  <vs-card type="5" class="card">
    <template #title>
      <h3 class="uppercase">{{ typeName(data.type) }}</h3>
    </template>
    <template #img ref="image">
      <img :src="dataImage" alt="" v-if="dataImage" />
      <img class="noImage" src="@/assets/img/no_image.jpg" v-else />
      <p
        class="image__text"
        :ref="`image${data.id}`"
        v-if="!dataImage"
        v-html="noImageText"
      ></p>
    </template>
    <template #text>
      <p>
        <span v-if="data.mark"
          >Марка: <b class="uppercase">{{ data.mark }}</b> <br
        /></span>
        <span v-if="data.diler"
          >Дилер: <b class="uppercase">{{ data.diler }}</b> <br
        /></span>
        Год: <b class="uppercase">{{ data.year }}</b
        ><br />
      </p>
    </template>
    <template #interactions>
      <vs-button
        shadow
        primary
        @click="addFavorite(data.id)"
        v-if="!data.favorit"
      >
        <i class="bx bx-plus"></i>
      </vs-button>
      <vs-button
        shadow
        primary
        @click="deleteFavorite(data.id)"
        v-if="data.favorit"
      >
        <i class="bx bx-minus"></i>
      </vs-button>
      <vs-button icon @click="openSite(data.link)">
        <i class="bx bx-folder-open"></i>
        <span>Открыть</span>
      </vs-button>
    </template>
  </vs-card>
</template>

<script>
export default {
  props: ['data'],
  name: 'Card',
  data: () => ({
    image: null,
    noImageText: 'Нет изображения',
  }),
  methods: {
    typeName(id) {
      return this.$store.getters.typeName(id);
    },
    openSite(link) {
      window.open(link, '_blank');
    },
    addFavorite(id) {
      this.$store.dispatch('addFavorite', id);
      const favorits = JSON.parse(localStorage.getItem('favorits'));
      favorits.push(id);
      localStorage.setItem('favorits', JSON.stringify(favorits));
    },
    deleteFavorite(id) {
      this.$store.dispatch('removeFavorite', id);
      let favorits = JSON.parse(localStorage.getItem('favorits'));
      favorits = favorits.filter((f) => f !== id);
      localStorage.setItem('favorits', JSON.stringify(favorits));
    },
  },
  computed: {
    dataImage() {
      return this.data.screenshot
        ? 'https://api.tandemdev.ru:10000/' + this.data.screenshot
        : false;
    },
    darkTheme() {
      return this.$store.getters.darkTheme;
    },
  },
};
</script>

<style lang="sass">
.card
	.vs-card
		background-color: var(--vs-theme-bg)
	.vs-card__img
		height: 100%
		img
			z-index: 2
			position: relative
			transform: scale(1.5)
		.noImg
			z-index: 1
	.image__text
		position: absolute
		width: 290px
		text-align: center
		z-index: 3
[vs-theme="dark"]
	.card
		.vs-card__img
			&::after
				content: ''
				position: absolute
				left: 0
				top: 0
				right: 0
				bottom: 0
				background-color: #47484b
				z-index: 2
				transition: .3s ease
			img
				position: relative
				z-index: 3
</style>
