import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import SecureView from '../views/SecureView.vue';
import RegisterView from '../views/RegisterView.vue';
import AdminView from '../views/AdminView.vue';
import CatalogView from '../views/CatalogView.vue';
import catalogSiteView from '../views/catalogSiteView.vue';
import CatalogCreateView from '../views/CatalogCreateView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'Авторизация',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LoginView,
  },
  {
    path: '/secure',
    name: 'secure',
    component: SecureView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
  },
  {
    path: '/admin/catalog',
    name: 'catalog',
    component: CatalogView,
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/admin/catalog/:id',
    component: catalogSiteView,
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/admin/create',
    component: CatalogCreateView,
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
      });
    } else {
      let user = JSON.parse(localStorage.getItem('user'));
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (user && user.is_admin == 1) {
          next();
        } else {
          next({ path: '/secure' });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next();
    } else {
      next({ path: '/' });
    }
  } else {
    next();
  }
});

export default router;
