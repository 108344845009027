<template>
  <form class="loginForm" action="#" method="post" @submit.prevent="login">
    <div class="loginForm__item">
      <vs-input v-model="email" placeholder="E-mail">
        <template #icon>
          <i class="bx bx-user"></i>
        </template>
        <template #message-danger v-if="emailError">
          Обязательное поле
        </template>
      </vs-input>
    </div>
    <div class="loginForm__item">
      <vs-input v-model="password" placeholder="Пароль">
        <template #icon>
          <i class="bx bx-lock-open-alt"></i>
        </template>
        <template #message-danger v-if="passError">
          Обязательное поле
        </template>
      </vs-input>
    </div>
    <div class="loginForm__item">
      <vs-button type="submit" flat> Войти </vs-button>
      <div class="vs-input__message vs-input__message--danger">
        {{ messageError }}
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data: () => ({
    email: '',
    password: '',
    emailError: false,
    passError: false,
    messageError: '',
  }),
  methods: {
    login() {
      !this.password.length
        ? (this.passError = true)
        : (this.passError = false);
      !this.email.length ? (this.emailError = true) : (this.emailError = false);
      if (this.password.length > 0) {
        this.$http
          .post('https://api.tandemdev.ru:10000/login', {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            this.messageError = '';
            let is_admin = response.data.user.is_admin;
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('jwt', response.data.token);

            if (localStorage.getItem('jwt') != null) {
              this.$store.dispatch('login').then(() => {
                this.$router.push('/');
              });
            }
          })
          .catch((error) => {
            console.error(error.response);
            this.messageError = error.response.data;
          });
      }
    },
  },
};
</script>

<style lang="sass">
.loginForm
	background-color: var(--vs-theme-layout)
	padding: 20px
	border-radius: 20px
	&__item
		margin: 10px
</style>
