<template>
  <div id="app">
    <nav-bar></nav-bar>
    <AdminSideBar v-if="isAdmin" />
    <div class="container content">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue';
import AdminSideBar from '@/components/AdminSideBar.vue';
export default {
  components: { NavBar, AdminSideBar },
  async created() {
    await this.$store.dispatch('fetchData');
    await this.$store.dispatch('fetchMarks');
    await this.$store.dispatch('fetchDilers');
  },
  computed: {
    isAdmin() {
      return this.$route.path.indexOf('admin') !== -1 ? true : false;
    },
  },
};
</script>
<style lang="sass">
@import "styles/main.sass"
.container
	display: block
	width: 100%
	max-width: 1200px
	padding: 0px 15px
	margin: 0 auto
body
	background-color: var(--vs-theme-bg)
	color: rgba(var(--vs-text))
	width: calc(100vw - 15px)
	overflow-x: hidden
.content
	margin-top: 44px
	padding-top: 20px
h1
	font-size: 35px
	text-align: center
	font-weight: 600
	margin-bottom: 50px
</style>
