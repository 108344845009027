<template>
  <div class="adminCatalog">
    <h1>Каталог сайтов</h1>
    <vs-button to="/admin/create" class="adminCatalog__create">
      <i class="bx bx-plus"></i> Добавить сайт
    </vs-button>
    <AdminCatalog />
  </div>
</template>

<script>
import AdminCatalog from '@/components/AdminCatalog.vue';
export default {
  components: { AdminCatalog },
};
</script>

<style lang="sass">
.adminCatalog__create
	margin-left: auto
</style>
