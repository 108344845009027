import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login: localStorage.getItem('user') ? true : false,
    darkTheme: false,
    data: [],
    types: [
      {
        id: 'auto',
        name: 'Автомобили',
      },
      {
        id: 'buyout',
        name: 'Выкуп',
      },
      {
        id: 'realty',
        name: 'Недвижимость',
      },
      {
        id: 'service',
        name: 'Сервис',
      },
      {
        id: 'other',
        name: 'Прочие',
      },
    ],
    marks: [],
    dilers: [],
  },
  getters: {
    isLoggedIn: (state) => !!state.login,
    darkTheme: (state) => state.darkTheme,
    types: (state) => state.types,
    typeName: (state) => (id) => state.types.find((x) => x.id === id).name,
    getData: (state) => state.data,
    getMarks: (state) => state.marks,
    getDilers: (state) => state.dilers,
    site: (state) => (id) => state.data.find((item) => item.id === id),
  },
  mutations: {
    logout(state) {
      state.login = false;
    },
    login(state) {
      state.login = true;
    },
    setDarkTheme(state) {
      state.darkTheme = !state.darkTheme;
    },
    SET_DATA(state, data) {
      if (!localStorage.getItem('favorits')) {
        localStorage.setItem('favorits', '[]');
      }
      const favorits = JSON.parse(localStorage.getItem('favorits'));
      const newData = [];
      data.forEach((item) => {
        const s = favorits.find((f) => f === item.id);
        s ? (item.favorit = true) : (item.favorit = false);
        newData.push(item);
      });
      state.data = newData;
    },
    SET_MARKS(state, data) {
      state.marks = data;
    },
    SET_DILERS(state, data) {
      state.dilers = data;
    },
    ADD_FAV(state, id) {
      state.data.find((f) => f.id === id).favorit = true;
    },
    REM_FAV(state, id) {
      state.data.find((f) => f.id === id).favorit = false;
    },
  },
  actions: {
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout');
        localStorage.removeItem('user');
        resolve();
      });
    },
    login({ commit }) {
      return new Promise((resolve, reject) => {
        commit('login');
        resolve();
      });
    },
    getDarkTheme({ commit }) {
      commit('setDarkTheme');
    },
    async fetchData({ commit }) {
      try {
        const data = await axios.get('https://api.tandemdev.ru:10000/sites/', {
          'Access-Control-Allow-Origin': '*',
          mode: 'no-cors',
        });
        commit('SET_DATA', data.data);
      } catch (err) {
        console.log(err);
      }
    },
    async fetchMarks({ commit }) {
      try {
        const data = await axios.get(
          'https://api.tandemdev.ru:10000/sites/marks/',
          {
            'Access-Control-Allow-Origin': '*',
            mode: 'no-cors',
          }
        );
        commit('SET_MARKS', data.data);
      } catch (err) {
        console.log(err);
      }
    },
    async fetchDilers({ commit }) {
      try {
        const data = await axios.get(
          'https://api.tandemdev.ru:10000/sites/dilers/',
          {
            'Access-Control-Allow-Origin': '*',
            mode: 'no-cors',
          }
        );
        commit('SET_DILERS', data.data);
      } catch (err) {
        console.log(err);
      }
    },
    addFavorite({ commit }, id) {
      commit('ADD_FAV', id);
    },
    removeFavorite({ commit }, id) {
      commit('REM_FAV', id);
    },
  },
  modules: {},
});
