<template>
  <div class="cards">
    <vs-row justify="center" class="cards__filters">
      <vs-select
        label="Выбрать Тип"
        v-model="typeModel"
        @change="firstPage"
        class="cards__filter"
      >
        <vs-option label="Все" value="all">Все</vs-option>
        <vs-option
          :label="type.name"
          :value="type.id"
          v-for="type in types"
          :key="type.id"
        >
          {{ type.name }}
        </vs-option>
      </vs-select>

      <vs-select
        label="Выбрать марку"
        v-model="markModel"
        :disabled="
          typeModel !== 'service' &&
          typeModel !== 'auto' &&
          typeModel !== '' &&
          typeModel !== 'all'
        "
        class="cards__filter"
      >
        <vs-option label="Все" value="all">Все</vs-option>
        <vs-option
          :label="markName(mark)"
          :value="mark"
          v-for="mark in marks"
          :key="mark"
        >
          {{ mark }}
        </vs-option>
      </vs-select>

      <vs-select
        label="Выбрать дилера"
        v-model="dilerModel"
        :disabled="
          typeModel !== 'service' &&
          typeModel !== 'auto' &&
          typeModel !== '' &&
          typeModel !== 'all'
        "
        class="cards__filter"
      >
        <vs-option label="Все" value="all">Все</vs-option>
        <vs-option
          :label="diler"
          :value="diler"
          v-for="diler in dilers"
          :key="diler"
        >
          {{ diler }}
        </vs-option>
      </vs-select>
      <vs-select label="Выбрать год" v-model="yearModel" class="cards__filter">
        <vs-option label="Все" value="all">Все</vs-option>
        <vs-option label="2017" value="2017"> 2017 </vs-option>
        <vs-option label="2018" value="2018"> 2018 </vs-option>
        <vs-option label="2019" value="2019"> 2019 </vs-option>
        <vs-option label="2020" value="2020"> 2020 </vs-option>
        <vs-option label="2021" value="2021"> 2021 </vs-option>
        <vs-option label="2022" value="2022"> 2022 </vs-option>
        <vs-option label="2023" value="2023"> 2023 </vs-option>
        <vs-option label="2024" value="2024"> 2024 </vs-option>
      </vs-select>
    </vs-row>
    <div class="cards__list">
      <transition-group name="fade">
        <card v-for="card in cards" :key="card.id" :data="card" />
      </transition-group>
    </div>

    <Pagination :data-length="cardsLength" @p-change="changeData" />
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import Pagination from '@/components/Pagination.vue';
export default {
  name: 'CardList',
  components: { Card, Pagination },
  data: () => ({
    show: 6,
    page: 0,
    markModel: 'all',
    typeModel: 'all',
    dilerModel: 'all',
    yearModel: 'all',
  }),
  methods: {
    changeData(page) {
      this.page = +page - 1;
    },
    firstPage() {
      document.querySelector('.vs-pagination__button').click();
    },
    markName(mark) {
      if (mark) return mark.charAt(0).toUpperCase() + mark.slice(1);
    },
    filterData(data) {
      if (this.typeModel && this.typeModel !== 'all')
        data = data.filter((item) => item.type === this.typeModel);
      if (this.markModel && this.markModel !== 'all')
        data = data.filter((item) => item.mark === this.markModel);
      if (this.dilerModel && this.dilerModel !== 'all')
        data = data.filter((item) => item.diler === this.dilerModel);
      if (this.yearModel && this.yearModel !== 'all')
        data = data.filter((item) => item.year === this.yearModel);
      return data;
    },
  },
  computed: {
    cards() {
      return this.$store.getters.getData
        ? this.filterData(this.$store.getters.getData)
            .filter((item) => item.on)
            .slice(this.page * this.show, this.page * this.show + this.show)
        : [];
    },
    marks() {
      return this.$store.getters.getMarks;
    },
    types() {
      return this.$store.getters.types;
    },
    dilers() {
      return this.$store.getters.getDilers;
    },
    cardsLength() {
      return this.$store.getters.getData
        ? Math.ceil(
            (this.filterData(this.$store.getters.getData).length - 1) /
              this.show
          )
        : 0;
    },
  },
};
</script>

<style lang="sass">
.cards__list > span
	display: grid
	grid-template-columns: repeat(3, 1fr)
	gap: 20px
	margin-top: 50px
.cards__filters
	background: rgba(var(--vs-background), 1)
	padding: 10px
	flex-wrap: wrap
.cards__filter
	margin: 20px 20px 10px 20px
@media only screen and (max-width: 1200px)
	.cards__list > span
		grid-template-columns: repeat(2, 1fr)
@media only screen and (max-width: 560px)
	.cards__list > span
		display: flex
		flex-wrap: wrap
		justify-content: center
</style>
